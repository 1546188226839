import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FlatList, Text, TextInput, View, TouchableOpacity, StyleSheet } from 'react-native';
import { globalFonts } from '../../shared/globalStyling';


export const InstructionsView = ({ partner_str, userLanguage, partnerLanguage }) => {

    return (
      <View style={{alignItems: "center", justifyContent: "center"}}>
        <Text style={globalFonts.title3}>How it Works:</Text>

        <Text style={globalFonts.subtitle}>{partner_str}'s messages will appear in {userLanguage} since you're learning {userLanguage}</Text>
        <Text style={globalFonts.subtitle}>Your messages will appear to {partner_str} in {partnerLanguage}, since {partner_str} is learning {partnerLanguage}</Text>
        <Text style={globalFonts.colorSubtitle}>Each message you send will be immediately graded with a correction. </Text>

        <Text style={globalFonts.title3}>Say hi!</Text>


      </View>


    );
  };
  