import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Text } from 'react-native';
import userDetailsManager from '../../managers/UserDetailsManager';
import { swiftStyles } from '../../shared/swiftStyles';
import { getRandomUser } from '../../shared/sharedFunctions';
import {FlolangTextField} from '../../shared/UIElements/FlolangTextField';
import {FlolangButton} from '../../shared/UIElements/FlolangButton';
import { FlolangDivider } from '../../shared/UIElements/FlolangDivider';
import {globalFonts} from '../../shared/globalStyling';
import { viewBools } from '../../shared/viewBoolBus';

export const PartnerSetter = () => {
  const [partnerName, setPartnerName] = useState("");
  const [warning, setWarning] = useState("");

  const handleUpdatePartner = () => {
    if (userDetailsManager.username_str == partnerName){
      setWarning("You cannot be your own partner (sorry).");
    }else{
      if (partnerName != ""){
        userDetailsManager.updateUserDetails({
          partnerUsername_str: partnerName,
        });
        viewBools.setViewBool("showPartnerSelection", false);
        viewBools.setViewBool("showMessagesWindow", true);
      }else{
        setWarning("You must provide a partner ID.");
      }

    }
  };

  const handleRandomPartner = () => {
    getRandomUser(data => {
      if (data && userDetailsManager.username_str !== data.username_str) {
        
        setPartnerName(data.username_str);
        if (data.username_str == "Harold" || data.username_str == "Marie"){
          setWarning(`${data.username_str} is a bot, not a human`);
        }

      } 
    });
  };
  
  const handleAIToggle = () => {
    const bots = ["Harold", "Marie"];
    const randomInt = Math.floor(Math.random() * bots.length);
    setPartnerName(bots[randomInt]);
    setWarning(`${bots[randomInt]} is a bot, not a human`);
  };
  

  return (
    <View style={styles.container}>

      <View>
        <Text style={globalFonts.title2}>Choose a partner</Text>
      </View>
      <View>
        <Text style={globalFonts.subtitle}>Chat with a Friend, Bot, or Random Learner.</Text>
      </View>
      
      <FlolangDivider thickness={2} width={50} />
      {/* <View>
        <Text style={globalStyles.subtitle}>Feeling Adventurous? Roll the Dice. </Text>
      </View> */}
      

      <View style={styles.topRow}>
        <View style={styles.randomButtonContainer}>
          <FlolangButton title="🎲" isDisabled={false} primaryStyle={true} action={handleRandomPartner} iconName={''} />
        </View>
        <View style={styles.textFieldContainer}>
          <FlolangTextField
            placeholderText="Enter a Partner ID"
            varToModify={partnerName}
            setVarToModify={setPartnerName}
            onEnterPressed={handleUpdatePartner}
          />
        </View>
      </View>

      <FlolangButton title="Set Partner" isDisabled={false} primaryStyle={true} action={handleUpdatePartner} iconName={''} />
      
      <Text style={{ color: 'red' }}>{warning}</Text>
      <FlolangDivider thickness={2} width={50} />

      <FlolangButton title="🤖 Practice with an AI" isDisabled={false} primaryStyle={false} action={handleAIToggle} iconName={''} />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  textFieldContainer: {
    flex: 3,
  },
  randomButtonContainer: {
    flex: 1,
  },

});
