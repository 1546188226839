import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Text } from 'react-native';
import userDetailsManager from '../../managers/UserDetailsManager';
import { FlolangButton } from '../../shared/UIElements/FlolangButton';
import { LanguageScroller } from './LanguageScroller/LanguageScroller';
import { viewBools } from '../../shared/viewBoolBus';
import { globalColors, globalFonts } from '../../shared/globalStyling';

export const LanguageSetter = () => {
  const [nativeLanguage, setNativeLanguage] = useState(userDetailsManager.userNativeLanguage_str);
  const [foreignLanguage, setForeignLanguage] = useState(userDetailsManager.userForeignLanguage_str);

  const handleUpdate = () => {
    viewBools.setViewBool("showLanguageSelection", false)
    viewBools.setViewBool("showMessagesWindow", true)

    userDetailsManager.updateUserDetails({
      userNativeLanguage_str: nativeLanguage,
      userForeignLanguage_str: foreignLanguage,
    });
  };

  return (
    <View style={styles.container}>
      <Text style={globalFonts.title2}>Choose your languages</Text>

      <Text style={globalFonts.subtitle}>Pick a language to <Text style={{color:globalColors.primary}}>learn: </Text> <Text style={{color:globalColors.flolangPink}}>{foreignLanguage}</Text></Text>
      <LanguageScroller language={foreignLanguage} setLanguage={setForeignLanguage}/>
      <Text style={globalFonts.subtitle}>Pick a language you <Text style={{color:globalColors.primary}}>know: </Text> <Text style={{color:globalColors.flolangBlue}}>{nativeLanguage}</Text></Text>
      <LanguageScroller language={nativeLanguage} setLanguage={setNativeLanguage}/>

      <FlolangButton title="Set Languages" isDisabled={false} primaryStyle={true} action={handleUpdate} iconName={""}  />

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  input: {
    borderBottomWidth: 1,
    width: 200,
    marginBottom: 10,
    padding: 5,
  },
});


