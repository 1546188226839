import { makeAutoObservable, reaction } from 'mobx';
import { collection, addDoc, query, orderBy, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../firebaseConfig';
import { Message } from '../models/Message';
import { v4 as uuidv4 } from 'uuid';


import userDetailsManager from './UserDetailsManager';
import { UserDetails as initialPartnerUserDetails } from '../models/UserDetails';
import { app, db, auth } from '../../AppInit';
import { getUserInfo } from '../shared/sharedFunctions';





class MessagesManager {
  messages = [];
  lastMessage = {};
  user = null;
  partnerUserDetails = initialPartnerUserDetails; 


  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.user,
      (user) => {
        console.log('User changed:', user);
      }
    );

    onAuthStateChanged(auth, (currentUser) => {
      this.setUser(currentUser);
    });
  }

  setUser(currentUser) {
    this.user = currentUser;
  }

  setMessages(newMessages) {
    this.messages = newMessages;
    this.lastMessage = newMessages[newMessages.length - 1] || {};
  }

  async getPartnerUserDetails(partnerID, onSuccess) {
    await getUserInfo(partnerID, (userDetails) => {
      if (userDetails) {
        this.partnerUserDetails = userDetails; 
        if (onSuccess) onSuccess(userDetails);
      }
    });
  }
  
  

  getMessages(partnerID, isBot) {
    try {
      const messagesQuery = query(
        collection(db, 'users', userDetailsManager.username_str, isBot ? 'botPartnerConversations' : 'peerPartnerConversations', partnerID, 'messages'),
        orderBy('timestamp_int')
      );
  
      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const newMessages = snapshot.docs.map(doc => doc.data());
        this.setMessages(newMessages);
      });
  
      return unsubscribe;
    } catch (error) {
      console.error("An error occurred while getting messages:", error);
    }
  }
  

  async sendMessage(messageContent, receiverID, isBot) {
    const id = uuidv4();
    const timestamp = Date.now();

    const senderMessage_obj = {
      ...Message,
      id,
      timestamp_int: timestamp,
      message_str: messageContent,
      senderID_str: userDetailsManager.username_str,
      userForeignLanguage_str: userDetailsManager.userForeignLanguage_str,
      userNativeLanguage_str: userDetailsManager.userNativeLanguage_str,
      partnerNativeLanguage_str: isBot ? userDetailsManager.userForeignLanguage_str : this.partnerUserDetails.userForeignLanguage_str,
      partnerForeignLanguage_str: isBot ? userDetailsManager.userForeignLanguage_str : this.partnerUserDetails.userNativeLanguage_str,
      receiverID_str: receiverID,
      received_bool: false
    };

    const receiverMessage_obj = {
        ...Message,
        id,
        timestamp_int: timestamp,
        message_str: messageContent,
        senderID_str: userDetailsManager.username_str,
        userForeignLanguage_str: userDetailsManager.userForeignLanguage_str,
        partnerNativeLanguage_str: isBot ? userDetailsManager.userForeignLanguage_str : this.partnerUserDetails.userNativeLanguage_str,
        partnerForeignLanguage_str: isBot ? userDetailsManager.userForeignLanguage_str : this.partnerUserDetails.userForeignLanguage_str,
        receiverID_str: receiverID,
        received_bool: true
      };

    try {
      const senderDocPath = doc(db, 'users', userDetailsManager.username_str, isBot ? 'botPartnerConversations' : 'peerPartnerConversations', receiverID, 'messages', id);
      const receiverDocPath = doc(db, 'users', receiverID, isBot ? 'botPartnerConversations' : 'peerPartnerConversations', userDetailsManager.username_str, 'messages', id);

      await setDoc(senderDocPath, senderMessage_obj);
      await setDoc(receiverDocPath, receiverMessage_obj);

      console.log('Message sent with shared ID:', id);
    } catch (error) {
      console.error('Error sending message:', error);
      console.log('Error sending message:', error);

    }
  }
}

const messagesManager = new MessagesManager();
export default messagesManager;

