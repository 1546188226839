import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Button} from 'react-native';
// Import your components and libraries here...
import {MessagesView} from './src/components/MessagesView/MessagesView';
import { observer } from "mobx-react-lite";
import { viewBools } from './src/shared/viewBoolBus';
import { LanguageSetter } from './src/components/Setters/LanguageSetter';
import { PartnerSetter } from './src/components/Setters/PartnerSetter';
import userDetailsManager from './src/managers/UserDetailsManager';
import messagesManager from './src/managers/MessagesManager';
import { AuthenticationView } from './src/components/AuthenticationView/AuthenticationView';
import { UsernameSetter } from './src/components/AuthenticationView/UsernameSetter';
import { app, db, auth, stripePromise } from './AppInit';
import { FlolangHeader } from './src/components/FlolangHeader/FlolangHeader';

import { AuthenticationTitle } from './src/components/AuthenticationView/AuthenticationTitle';

import { PaymentPage } from './src/components/Payments/PaymentPage';
import { FlolangButton } from './src/shared/UIElements/FlolangButton';
import { globalFonts, globalColors } from './src/shared/globalStyling';
import { ConversationListView } from './src/components/MessagesView/ConversationListView'
const frontendConfig = require('./frontendConfig.json');  


// import { swift } from './src/shared/swiftStyles';
// import { FlolangSpacer } from './src/shared/UIElements/FlolangSpacer';

if (!frontendConfig.IS_TEST_MODE) {
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
}


const App = observer(() => {
  // const [loggedIn, setLoggedIn] = useState(true); // CHANGED FROM false FOR TESTING
  // const [userUID, setUserUID] = useState("9CbvaNS4oFAErG7TOxuyQeRM4cT4"); // CHANGED FROM "" FOR TESTING
  // const [username, setUsername] = useState('nic');  // CHANGED FROM "" FOR TESTING

  

  const [loggedIn, setLoggedIn] = useState(false); // CHANGED FROM false FOR TESTING
  const [userUID, setUserUID] = useState(""); // CHANGED FROM "" FOR TESTING
  const [username, setUsername] = useState("");  // CHANGED FROM "" FOR TESTING
  const [isDark, setIsDark] = useState(false);  // CHANGED FROM "" FOR TESTING

  useEffect(() => {
    // This sets an event listener as soon as the app starts
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const fetchedUsername = await userDetailsManager.getUsernameFromUID(user.uid);
      console.log("fetchedUsername: ", fetchedUsername)
      if (user && fetchedUsername) {
        console.log("firing subscribe check")
        // User is signed in
        setLoggedIn(true);
        setUserUID(user.uid);
  
        // Fetch username from uid
        const fetchedUsername = await userDetailsManager.getUsernameFromUID(user.uid);
        setUsername(fetchedUsername);
  
        // Fetch additional user details
        if (fetchedUsername) {
          await userDetailsManager.getUserDetailsFromUsername(fetchedUsername);
        }
    
        // Potentially more logic here for username or other properties
      } else {
        // User is signed out
        console.log("firing logged out check")
        setLoggedIn(false);
        setUserUID('');
        setUsername(''); 
        
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    const { partnerUsername_str, userForeignLanguage_str, username_str, userNativeLanguage_str } = userDetailsManager;
    messagesManager.getPartnerUserDetails(partnerUsername_str);
  
    if (username_str) {
      viewBools.setViewBool("showLanguageSelection", true);
    }
  
    if (username_str && userNativeLanguage_str && userForeignLanguage_str) {
      viewBools.setViewBool("showPartnerSelection", true);
      viewBools.setViewBool("showLanguageSelection", false);
      viewBools.setViewBool("showMessagesWindow", false);

    }
  
    if (partnerUsername_str && username_str && userNativeLanguage_str && userForeignLanguage_str) {
      viewBools.setViewBool("showPartnerSelection", false);
      viewBools.setViewBool("showMessagesWindow", true);
    }

  }, [userDetailsManager.partnerUsername_str, userDetailsManager.userForeignLanguage_str, userDetailsManager.username_str, userDetailsManager.userNativeLanguage_str]);
  
return (
    <View style={styles.container}>
      <Text style={globalFonts.title}>Flolang Is Currently Down.</Text>
      <Text style={globalFonts.colorSubtitle}>Questions: official_flolang@gmail.com</Text>

    </View>
  );
});




export default App;


const styles = StyleSheet.create({
  
  container: {
    flex: 1,
    backgroundColor: globalColors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loggedInContainer: {
    flex: 1,
  },
  header: {
    alignSelf: 'stretch',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  authHeader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  otherViews: {
    flex: 2,
    justifyContent: 'flex-start', 
    alignItems: 'center',
    // backgroundColor: "red"
  },

});






