import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { firebaseConfig } from './firebaseConfig';
import { Platform } from 'react-native';
import { loadStripe } from '@stripe/stripe-js';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectAuthEmulator } from 'firebase/auth';



const frontendConfig = require('./frontendConfig.json');  
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

const stripePromise = loadStripe(frontendConfig.IS_TEST_MODE ? frontendConfig.TEST_STRIPE_PUBLIC_KEY :frontendConfig.LIVE_STRIPE_PUBLIC_KEY);

  
if (frontendConfig.IS_TEST_MODE) {
    connectFirestoreEmulator(db, frontendConfig.LOCAL_IP, 8080);
    connectAuthEmulator(auth, `http://${frontendConfig.LOCAL_IP}:9099`);
  }

export { app, db, auth, stripePromise };