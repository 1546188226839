import { makeAutoObservable } from "mobx";

class ViewBools {
  showMessagesWindow = false;
  showPartnerSelection = false;
  showLanguageSelection = false;
  showBotSetter = false;
  showPaymentPage = false;

  constructor() {
    makeAutoObservable(this);
  }

  setViewBool(key, value) {
    console.log(key + " set to " + value)
    this[key] = value;
  }
}

export const viewBools = new ViewBools();
