import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import { globalColors, globalFonts } from '../../../../shared/globalStyling';

export const LanguageBubble = ({ language, flag, isSelected, onSelect }) => {
  return (
    <TouchableOpacity
      style={[
        styles.languageBox,
        isSelected ? styles.selectedLanguageBox : {}
      ]}
      onPress={() => onSelect(language)}
    >
      <Text style={styles.flagText}>{flag}</Text>
      <Text style={styles.languageText}>{language}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  languageBox: {
    padding: 10,
    marginRight: 10,
    borderRadius: 10,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1, // Add this line for black outline
    borderColor: globalColors.primary
  },
  selectedLanguageBox: {
    backgroundImage: "linear-gradient(to bottom right, #3DA3DC, #F622FB)",
    borderWidth: 0, // Override the border for selected box
  },
  languageText: {
    color: globalColors.primary,
    fontWeight: 'bold',
  },
  flagText: {
    fontSize: 18,
  },
});
