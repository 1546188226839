import React, { useState } from 'react';
import { View, Button, StyleSheet, Text, TextInput } from 'react-native';
import { signInAnonymously, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import userDetailsManager from '../../managers/UserDetailsManager';
import { isKeyInCollection } from '../../shared/sharedFunctions';
import {FlolangTextField}   from '../../shared/UIElements/FlolangTextField';
import  {FlolangButton } from '../../shared/UIElements/FlolangButton';
import { globalFonts } from '../../shared/globalStyling';

export const UsernameSetter = ({ setLoggedIn, userUID, username, setUsername }) => {
  const [showUsernameTaken, setShowUsernameTaken] = useState(false);



  const chooseUsername = async () => {
    try {
      if (username) {
        const found = await isKeyInCollection("users", username.toLowerCase().replace(/\s+/g, '_'));
        if (found) {
          setShowUsernameTaken(true);
        } else {
          await userDetailsManager.createUser(username.toLowerCase().replace(/\s+/g, '_'), userUID);
          setLoggedIn(true);
        }
      } else {
        secureLog("Please enter a username.");
      }
    } catch (error) {
      secureLog("error during sign in .");

    }
  };
  

  return (
    <View style={styles.container}>
      <Text style={globalFonts.title2}>Choose a Username</Text>
      <FlolangTextField
        placeholderText="Username"
        varToModify={username}
        setVarToModify={setUsername}
        onEnterPressed={chooseUsername}
      />
    {showUsernameTaken && ( <Text style = {{color: "red"}}>Username taken. Try again.</Text> )}

      <FlolangButton title={"Login"} isDisabled={false} primaryStyle={true} iconName="" action={chooseUsername} />

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    height: 40,
    width: 200,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 20,
    paddingLeft: 10
  },
});

