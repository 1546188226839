

export const Message = {
    id: "",
    timestamp_int: 0,
    message_str: "",
    userNativeTranslation_str: "Translating..",
    userForeignTranslation_str: "Translating..",
    partnerNativeTranslation_str: "Translating..",
    partnerForeignTranslation_str: "Translating..",
    userNativeLanguage_str: "",
    userForeignLanguage_str: "",
    partnerNativeLanguage_str: "",
    partnerForeignLanguage_str: "",
    senderID_str: "",
    receiverID_str: "",
    received_bool: false
  };
  

 