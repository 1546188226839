import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';
import { globalColors } from '../../../../shared/globalStyling';

export const BubbleTranslation = ({ item, gradeMessage }) => {
  return (
    <View>
      {
        item.received_bool ? (
          <View style={styles.translationGradient}>
            <Text style={{color: globalColors.primary, fontWeight: "bold"}}>{item.partnerForeignLanguage_str} Translation</Text>
            <Text style={{color: globalColors.primary}}>{item.partnerNativeTranslation_str}</Text>
          </View>
        ) : (
          <View style={!item.received_bool ? styles.translationGradient : null}>
            <Text style={{color: globalColors.primary, fontWeight: "bold"}}>{item.userForeignLanguage_str} Correction</Text>
            <View style={{ flexDirection: 'row' }}> {/* Horizontal Stack for gradeMessage */}
              {gradeMessage(item.userForeignTranslation_str, item.message_str)}
            </View>
          </View>
        )
      }
    </View>
  );
};

const styles = StyleSheet.create({
  translationGradient: {
    color: globalColors.secondary,
    borderRadius: 20,
    padding: 10,
    flexDirection: 'column', // Set to 'column' to stack children vertically
    borderColor: globalColors.primary,
    borderWidth: 1,
  },
});
