import React from 'react';
import { View, StyleSheet } from 'react-native';  // Changed Pressable to View

export const FlolangDivider = ({ thickness, width }) => {
  const dividerStyle = {
    height: thickness,  
    width: `${width}%`,  
    backgroundColor: 'grey',  
    marginVertical: 10,  
  };

  return (
    <View style={dividerStyle} /> 
  );
};
