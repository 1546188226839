import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FlatList, Text, TextInput, View, TouchableOpacity, StyleSheet } from 'react-native';

import { globalFonts } from '../../shared/globalStyling';

export const AuthenticationTitle = ({username }) => {
    // State for the component
    const [localState, setLocalState] = useState(null);

    return (
      <>
        <View style={styles.hstackContainer}>
          <Text style={globalFonts.title}>Welcome to </Text>
          <Text style={globalFonts.gradientTitle}>Flolang</Text>
          <Text style={globalFonts.title}>
            {username === "" ? '' : ' ,'}
          </Text>
        </View>

        {username !== "" ? (
          <Text style={globalFonts.title}>{'✨' + username.toLowerCase().replace(/\s+/g, '_')}</Text>
        ) : (
          <Text style={globalFonts.subtitle}>Speak New Languages With Friends</Text>
        )}

      </>
    );
  };
  

const styles = StyleSheet.create({


    
    hstackContainer: {
        flexDirection: 'row',
        alignItems: "baseline"
        },
    });