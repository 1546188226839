import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Text } from 'react-native';
import { languages } from '../../../shared/DBFunctions/DBFunctions';
import { LanguageBubble } from './LanguageBubble/LanguageBubble';

export const LanguageScroller = ({ language, setLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    if (language) {
      setSelectedLanguage(language);
    }
  }, [language]);

  const onSelect = (language) => {
    setSelectedLanguage(language);
    console.log("selected : " + language);
    setLanguage(language);
    // Add any additional logic here, like updating a global state or local storage
  };

  return (
    <View style={styles.container}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        {Object.keys(languages).map((language, index) => (
          <LanguageBubble
            key={index}
            language={language}
            flag={languages[language]}
            isSelected={selectedLanguage === language}
            onSelect={onSelect}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 80,
  },
});
