// required imports
import { StyleSheet } from 'react-native';

const isDarkmode = true 

export const globalColors= {
    primary: isDarkmode ? "white" : "black",
    secondary: isDarkmode ? "#838383" : "gray",
    background: isDarkmode ? "#3A3939" : "#EDEDED",
    flolangGreen: "#00FF38",
    flolangBlue: "cyan",
    flolangPink: "hotpink",
    flolangPrimaryGradient: "linear-gradient(to bottom right, #3DA3DC, #F622FB)",
    flolangSecondaryGradient: "linear-gradient(to bottom right, #3DA3DC, gray)",

}

export const globalFonts = StyleSheet.create({

  title: {
    fontSize: 50,
    fontWeight: 'bold',
    color: globalColors.primary
    },

  title2: {
    fontSize: 40,
    fontWeight: 'bold',
    color: globalColors.primary
  },

  title3: {
    fontSize: 30,
    fontWeight: 'bold',
    color: globalColors.primary
  },


  secondaryTitle2: {
    fontSize: 40,
    fontWeight: 'bold',
    color: globalColors.secondary
  },

  primarySubtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 20,
    color: globalColors.primary
  },
  primarySubtitle2: {
    fontSize: 10,
    fontWeight: 'bold',
    margin: 20,

    color: globalColors.primary
  },

  subtitle:{
    fontSize: 20,
    fontWeight: 'bold',
    margin: 20,
    color: globalColors.secondary
  },

  subtitle2:{
    fontSize: 10,
    fontWeight: 'bold',
    margin: 20,
    color: globalColors.secondary
  },


  colorSubtitle:{
    fontSize: 20,
    fontWeight: 'bold',
    margin: 20,
    color: globalColors.flolangPink
  },
  
  gradientTitle: {
    fontSize: 50,
    fontWeight: 'bold',
    color: "white",
    backgroundImage: "linear-gradient(to bottom right, #3DA3DC, #F622FB)",
    borderRadius: 10,  
    padding: 10,  
  },

  normal: {
    fontSize: 16,
    fontWeight: 'bold',
    color: globalColors.primary
    },

  
  
});