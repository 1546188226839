import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native';
import { globalFonts, globalColors } from '../../../../shared/globalStyling';
// Function to remove accent marks and special characters



export const BubbleText = ({ item, gradeMessage }) => {
  return (
    <>
      <View style={!item.received_bool ? styles.sentGradient : null}>
        {
          item.received_bool ? (
            <Text style={styles.receivedGradient}>{item.partnerForeignTranslation_str}</Text>
          ) : (
            gradeMessage(item.message_str, item.userForeignTranslation_str)
          )
        }
      </View>
    </>
  );
};

const styles = StyleSheet.create({
    receivedGradient: {
        color: "white",
        backgroundImage: globalColors.flolangPrimaryGradient,
        borderRadius: 20,  
        padding: 10, 
        flexDirection: 'row', 
        flexWrap: 'wrap' ,
        fontWeight: "bold"
    },
    sentGradient: {
        fontWeight: 'bold',
        color: "white",
        backgroundImage: globalColors.flolangSecondaryGradient,
        borderRadius: 20, 
        padding: 10, 
        flexDirection: 'row', 
        flexWrap: 'wrap',
        fontWeight: "bold"

    },
})

