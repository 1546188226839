
import { isKeyInCollection } from '../../shared/sharedFunctions';
import userDetailsManager from '../../managers/UserDetailsManager';

export const checkForUserAccount = async (newUserID, setUserUID, setLoggedIn) => {
    console.log("called check")
    isKeyInCollection("uids", newUserID).then(async found => {
      console.log("called is key")
      if (found) {
        console.log("user has account");
        const username = await userDetailsManager.getUsernameFromUID(newUserID);
        await userDetailsManager.getUserDetailsFromUsername(username).then(() => {
          setLoggedIn(true);
        });
      } else {
        console.log("user does not have an account (if they logged in w/o making username, it was never created)");
        setUserUID(newUserID);
      }
    });
  }