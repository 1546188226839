import { initializeApp } from 'firebase/app';

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase
export const firebaseConfig = {
    apiKey: "AIzaSyD9Vg99stWj0GyIAtcXuO-kjETBcAQ3YDA",
    authDomain: "flolang.ai",
    projectId: "fluenlang-719aa",
    storageBucket: "fluenlang-719aa.appspot.com",
    messagingSenderId: "42041544378",
    appId: "1:42041544378:web:1bc013fb46703ada1d3c83",
    measurementId: "G-S3WKQ3JSZJ"
  };

const app = initializeApp(firebaseConfig);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
