import { StyleSheet } from 'react-native';



export const swift = StyleSheet.create({
  HStack: {
    flexDirection: 'row',
    width: '100%',
  },
  VStack: {
    flexDirection: 'column', 
    width: '100%',

  },
});
