import React, { useState, useEffect } from 'react';
import { Platform, View, Text } from 'react-native';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import userDetailsManager from '../../managers/UserDetailsManager';
import { app, db, auth, stripePromise } from '../../../AppInit';
import { doc, setDoc,onSnapshot } from 'firebase/firestore';
import { FlolangButton } from '../../shared/UIElements/FlolangButton';
import { observer } from 'mobx-react-lite';
import { globalColors, globalFonts } from '../../shared/globalStyling';

export const PaymentComponent = observer(() => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [isPaid, setIsPaid] = useState(false);

  const cardStyle = {
    style: {
      base: {
        color: globalColors.primary,
        fontSize: "16px",
        fontWeight: "bold",
        '::placeholder': {
          color: '#737373',
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const commonFieldStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',  
  };

  const fieldStyle = {
    width: '100%', 
    minHeight: "52px",
    paddingLeft: "16px",
    paddingRight: "20px",
    borderRadius: "20px",
    borderWidth: "2px",
    borderColor: globalColors.primary,
    overflow: "hidden",
    borderStyle: "solid",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  };

  const smallFieldStyle = {
    width: '50%', 
    minHeight: "52px",
    paddingLeft: "16px",
    paddingRight: "20px",
    borderRadius: "20px",
    borderWidth: "2px",
    borderColor: globalColors.primary,
    overflow: "hidden",
    borderStyle: "solid",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  };


  const textInput = {
    width: '100%', 
    minHeight: "52px",
    paddingLeft: "5px",
    paddingRight: "20px",
    color: globalColors.primary,
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
    fontSize: "16px",
    fontWeight: "bold"
    }
  
  useEffect(() => {
    const uid = userDetailsManager.id;
    const uidRef = doc(db, 'uids', uid);
  
    // Listen for real-time updates
    const unsubscribe = onSnapshot(uidRef, (doc) => {
      if (doc.exists && doc.data().isPaid) {
        setLoading(false);
        setIsPaid(true);
        alert("Payment Success! Reload conversation for premium features.");

      }
    });
  
    // Cleanup
    return () => unsubscribe();
  }, []);
  


  const handlePayment = async () => {
    setLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    const uid = userDetailsManager.id;

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        alert("Failed to create payment method. Please try again.");
        setLoading(false);
        return;
      }

      const orderRef = doc(db, 'orders', `${uid}_${new Date().toISOString()}`);

      await setDoc(orderRef, {
        uid,
        paymentMethodId: paymentMethod.id,
      });

      if (userDetailsManager.isPaid) {
        alert("Payment Success! Reload conversation for premium features.");
        setLoading(false);
        setIsPaid(true)
        return;
      }

      setLoading(true);
    } catch (error) {
      alert("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div>
      {Platform.OS === 'web' && (
        <>
        
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={fieldStyle}>
              <input
                style={textInput}
                type="text"
                placeholder="Name on Card"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={fieldStyle}>
              <CardNumberElement options={cardStyle} />
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={smallFieldStyle}>
              <CardExpiryElement options={cardStyle} />
            </div>
            <div style={smallFieldStyle}>
              <CardCvcElement options={cardStyle} />
            </div>
          </div>

          <FlolangButton title={loading ? "Processing: Do not exit page" : "Subscribe to Pro Plan"} isDisabled={isPaid} primaryStyle={!isPaid} action={handlePayment} />
        </>
      )}
    </div>
  );
})