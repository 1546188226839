import React, { useEffect, useState, useRef } from 'react';
import {
  FlatList,
  Text,
  View,
  StyleSheet,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import { MessageBubble } from './MessageBubble/MessageBubble';
import messagesManager from '../../managers/MessagesManager';
import userDetailsManager from '../../managers/UserDetailsManager';
import { FlolangTextField } from '../../shared/UIElements/FlolangTextField';
import { FlolangButton } from '../../shared/UIElements/FlolangButton';
import { StudentView } from './StudentView';
import { FlolangDivider } from '../../shared/UIElements/FlolangDivider';
import { viewBools } from '../../shared/viewBoolBus';
import { InstructionsView } from './InstructionsView';
import { globalColors, globalFonts } from '../../shared/globalStyling';

export const MessagesView = observer(({ partner_str }) => {
  const messageLimit_int = 25;
  const [messageText, setMessageText] = useState('');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const flatListRef = useRef(null);


  const scrollToBottom = () => {
    flatListRef.current?.scrollToEnd({ animated: true });
  };
  
  
  useEffect(() => {
    scrollToBottom();
  }, [messagesManager.messages]);

  useEffect(() => {
    console.log("firing ")
    console.log(userDetailsManager.isPaid)
    console.log(userDetailsManager.messagesToday_int)
    if (userDetailsManager.messagesToday_int >= messageLimit_int && !userDetailsManager.isPaid) {
      setIsOverLimit(true);
      viewBools.setViewBool("showPaymentPage", true);
      viewBools.setViewBool("showMessagesWindow", false);
    } else {
      setIsOverLimit(false);
    }
  }, [userDetailsManager.messagesToday_int]);

  useEffect(() => {
    if (userDetailsManager.messagesToday_int >= messageLimit_int && !userDetailsManager.isPaid) {
      setIsOverLimit(true);
      viewBools.setViewBool("showPaymentPage", true);
      viewBools.setViewBool("showMessagesWindow", false);
    } else {
      setIsOverLimit(false);
    }

    messagesManager.getMessages(partner_str, messagesManager.partnerUserDetails.isBot);
  }, [messagesManager.partnerUserDetails.isBot, userDetailsManager.isPaid]);

  const handleSendMessage = () => {
    if (userDetailsManager.messagesToday_int >= messageLimit_int && !userDetailsManager.isPaid) {
      setIsOverLimit(true);
      viewBools.setViewBool("showPaymentPage", true);
      viewBools.setViewBool("showMessagesWindow", false);
    } else {
      setIsOverLimit(false);
    }

    messagesManager.sendMessage(messageText, partner_str, messagesManager.partnerUserDetails.isBot);
    const todayDate_str = new Date().toISOString().split('T')[0];
    userDetailsManager.incrementDailyMessageCount(todayDate_str);
    setMessageText('');
  };



  return (
    <View style={styles.container}>
      <View style={styles.studentViewContainer}>
        <StudentView isPartner={true} style={styles.studentViewRight} />
        <StudentView isPartner={false} style={styles.studentViewLeft} />
      </View>



      {isOverLimit && <Text>Over limit</Text>}
      <FlolangDivider thickness={2} width={85} />

      <View style={styles.flatListContainer}>
      {messagesManager.messages.length > 0 ?
        <FlatList
          ref={flatListRef}
          data={messagesManager.messages}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={[item.received_bool ? styles.received : styles.sent]}>
              <MessageBubble item={item} />
            </View>
          )}
          style={styles.list}
          onContentSizeChange={scrollToBottom}
          onLayout={scrollToBottom}
        /> : 
        <InstructionsView partner_str={userDetailsManager.partnerUsername_str} userLanguage={userDetailsManager.userNativeLanguage_str} partnerLanguage={messagesManager.partnerUserDetails.userForeignLanguage_str} />
      }
    </View>


      <View style={ styles.textFieldStyles }>
        <FlolangTextField placeholderText="Type your message" varToModify={messageText} 
          setVarToModify={(text) => setMessageText(text.substring(0, 250))} 
          onEnterPressed={handleSendMessage} />
        <FlolangButton title="send" isDisabled={false} primaryStyle={true} action={ !messageText!="" ? handleSendMessage : null} iconName={"send-outline"} />
      </View>
    </View>
  );
});


const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '85%',  // Set this to 80% of parent's width
    alignSelf: 'center',  // Center align this container
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,  // Rounded corners
    borderWidth: 1, 
    borderColor: globalColors.primary ,  // Color of the outline
    margin: 20,  // Padding around the content
    height: 300
  },
  
  studentViewContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '85%',
    margin: 20,
  },
  studentViewLeft: {
    alignSelf: 'flex-start',
  },
  studentViewRight: {
    alignSelf: 'flex-end',
  },
  list: {
    flex: 1,
    width: '85%',  
    alignSelf: "center",

  },

  received: {
    alignSelf: 'flex-start',
    margin: 10
  },
  sent: {
    alignSelf: 'flex-end',
    margin: 10
  },
  textFieldStyles: {
    width: '85%',  
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 20

  },
  flatListContainer: {
    height: 400,  
    width: '100%', 
  },
});
