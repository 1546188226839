import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FlatList, Text, TextInput, View, TouchableOpacity, StyleSheet } from 'react-native';
import userDetailsManager from '../../managers/UserDetailsManager';
import messagesManager from '../../managers/MessagesManager';
import { languages } from '../../shared/languages';
import { globalColors, globalFonts } from '../../shared/globalStyling';

export const StudentView = ({ isPartner }) => {

    //state declariont
    const [nativeLanguage, setNativeLanguage] = useState("");
    const [foreignLanguage, setForeignLanguage] = useState("");


    useEffect(() => {
        setNativeLanguage(isPartner ? messagesManager.partnerUserDetails.userNativeLanguage_str : userDetailsManager.userNativeLanguage_str)
        setForeignLanguage(isPartner ? messagesManager.partnerUserDetails.userForeignLanguage_str : userDetailsManager.userForeignLanguage_str)


    }, [messagesManager.partnerUserDetails]);

    return (
        <View style={isPartner ? styles.containerReceived : styles.containerSent}>
        <Text style={isPartner ? styles.nameStyleReceived : styles.nameStyleSent}>
            {isPartner ? messagesManager.partnerUserDetails.username_str : userDetailsManager.username_str}
        </Text>

        {/* Check if messagesManager.partnerUserDetails.isBot is true */}

        {messagesManager.partnerUserDetails.isBot && isPartner ? (
            <>
            <Text style={styles.learningStyle}>
                Learning Everything
            </Text>
            <Text style={styles.knowStyle}>
                Knows Nothing
            </Text>
            </>
        ) : (
            <>
            <Text style={styles.learningStyle}>
                Learning {foreignLanguage} {languages[foreignLanguage]}
            </Text>
            <Text style={styles.knowStyle}>
                Knows {nativeLanguage} {languages[nativeLanguage]}
            </Text>
            </>
        )}
        </View>

    );
  };

const styles = StyleSheet.create({
    containerReceived: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    containerSent: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    nameStyleSent: {
        fontSize: 25,
        fontWeight: 'bold',
        color: globalColors.flolangBlue
    },
    nameStyleReceived: {
        fontSize: 25,
        fontWeight: 'bold',
        color: globalColors.flolangPink
    },
    learningStyle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: globalColors.primary
    },
    knowStyle: {
        fontSize: 20,
        color: globalColors.secondary,
        fontWeight: 'bold',
    }

})
  