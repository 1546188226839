import React from 'react';
import { Pressable, Text, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons'; // Importing Ionicons as an example; you can import any set you prefer

export const FlolangTextButton = ({ title, isDisabled, isPrimaryStyle, action, iconName, fontSize }) => {

  // Function to handle text press
  const handlePress = () => {
    if (!isDisabled) {
      action();
    }
  };

  return (
    <Pressable
      onPress={handlePress}
      disabled={isDisabled}
    >
      { iconName && <Icon name={iconName} size={20} color={primaryStyle ? 'white' : 'gray'} style={styles.icon} /> }
      <Text style={isPrimaryStyle ? styles.primaryStyle : styles.secondaryStyle } >
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  primaryStyle: {
    color: "red",
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: "52px",
    justifyContent: 'center',
    borderRadius: 15,
    padding: 10,
    margin: 30,
  },
  secondaryStyle: {
    color: "blue",
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: "52px",
    justifyContent: 'center',
    borderRadius: 15,
    padding: 10,
    margin: 30,
  }

})