import { makeAutoObservable } from 'mobx';
import { doc, setDoc, updateDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { UserDetails as DefaultUserDetails } from '../models/UserDetails';
import { app, db, auth } from '../../AppInit';
import { FieldValue } from "firebase/firestore";  // or "@google-cloud/firestore" if you're using Cloud Functions
import {  } from '../shared/sharedFunctions';

class UserDetailsManager {
  constructor() {
    this.UserDetails = { ...DefaultUserDetails };
    Object.keys(DefaultUserDetails).forEach((key) => {
      this[key] = DefaultUserDetails[key];
    });
    makeAutoObservable(this);
  }

  updatePartialUserDetails(fields) {
    Object.assign(this, fields);  // Update fields at root level
    Object.assign(this.UserDetails, fields);  // Keep UserDetails object in sync
  }

  async getUsernameFromUID(uid) {
    try {
      const data = (await getDoc(doc(db, 'uids', uid))).data();
      return data ? data.username_str : null;
    } catch (e) {
      console.error('Error:', e);
      return null;
    }
  }

  async listPeerPartnerConversationDocNames(username) {
    try {
      const userPeerConversationsRef = collection(db, 'users', username, 'peerPartnerConversations');
  
      const querySnapshot = await getDocs(userPeerConversationsRef);
  
      const docNames = [];
      querySnapshot.forEach((doc) => {
        docNames.push(doc.id);
      });
  
      return docNames;
    } catch (error) {

        console.log('Error:', error);
    }
  }
  
  




  async getUserDetailsFromUsername(username) {
    console.log("getting details for " + username)
    const data = (await getDoc(doc(db, 'users', username))).data();
    if (data) {
      this.UserDetails = data;
      Object.assign(this, data);
    }
  }

  async createUser(username, UID) {
    this.updatePartialUserDetails({ username_str: username, id: UID });
    const userpath = doc(db, 'users', username);
    const uidpath = doc(db, 'uids', UID);

    setDoc(userpath, this.UserDetails)
      .then(() => console.log('Created username'))
      .catch((e) => console.error('Error:', e));

    setDoc(uidpath, { username_str: username, stripeCustomerId: "", isPaid: false })
      .then(() => console.log('Created UID'))
      .catch((e) => console.error('Error:', e));
  }

  async updateUserDetails(updatedFields) {
    this.updatePartialUserDetails(updatedFields);
    const path = doc(db, 'users', this.username_str);
    return updateDoc(path, updatedFields)
      .then(() => console.log('User details updated.'))
      .catch((e) => console.error('Error updating user details:', e));
  }

  async incrementDailyMessageCount(dateStr) {
    const userRef = doc(db, 'users', this.username_str);
    const { dailyMessageCounts_arr_obj = [], totalMessages_int = 0, messagesToday_int = 0, daysActive_int = 0 } = (await getDoc(userRef)).data();

    let index = dailyMessageCounts_arr_obj.findIndex(x => x.date === dateStr);
    let newDaysActive = daysActive_int;

    if (index === -1) {
      dailyMessageCounts_arr_obj.push({ date: dateStr, count: 1 });
      newDaysActive++;
    } else {
      dailyMessageCounts_arr_obj[index].count++;
    }

    var newTotalMessageCount_int = totalMessages_int + 1

    this.updateUserDetails({
      dailyMessageCounts_arr_obj,
      totalMessages_int: newTotalMessageCount_int,
      messagesToday_int: dateStr === new Date().toISOString().split('T')[0] ? messagesToday_int + 1 : messagesToday_int,
      daysActive_int: newDaysActive
    });
  }
}

export default new UserDetailsManager();
