import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { globalColors } from '../../../../shared/globalStyling';
export const CircleProgressBar = ({ percentage }) => {
  const radius = 25;
  const strokeWidth = 5;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <Svg height={radius * 2} width={radius * 2} 
      style={{ transform: [{ rotate: '-90deg' }] }}>
      <Circle
        stroke={globalColors.secondary}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        strokeWidth={strokeWidth}
      />
      <Circle
        stroke={globalColors.flolangGreen}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
      />
    </Svg>
  );
};
