import { doc, getDoc, collection, query, getCountFromServer, getDocs } from 'firebase/firestore';
import { app, db, auth } from '../../AppInit';
const frontendConfig = require('../../frontendConfig.json');  

export const getUserInfo = async (userID, completion) => {
  const docRef = doc(db, "users", userID);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      completion(docSnap.data());
    } else {
      console.log("No such user!");
      completion(null);
    }
  } catch (err) {
    console.error(err);
    completion(null);
  }
};

export const isKeyInCollection = async (collection, id) => {
  try {return (await getDoc(doc(db, collection, id))).exists();} 
  catch {return false;}
};


export const getRandomUser = async (completion) => {
  try {
    const usersQuery = query(collection(db, 'users'));
    const snapshot = await getDocs(usersQuery);

    if (snapshot.empty) {
      completion(null);
      return;
    }

    const documents = snapshot.docs;
    const randomIndex = Math.floor(Math.random() * documents.length);
    const randomUserDocument = documents[randomIndex];
    const userDetails = randomUserDocument.data();

    completion(userDetails);
  } catch (error) {
    console.error(error);
    completion(null);
  }
};
