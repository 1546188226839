import React, { useState, useEffect } from 'react';
import { View, Button, StyleSheet, Text, TouchableOpacity, Pressable } from 'react-native';
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';

import { FlolangButton } from '../../shared/UIElements/FlolangButton';
import { FlolangTextField } from '../../shared/UIElements/FlolangTextField';
import { FlolangDivider } from '../../shared/UIElements/FlolangDivider';
import { SignUpComponent } from './SignUpComponent';
import { LoginComponent } from './LoginComponent';

import { checkForUserAccount} from '../../shared/DBFunctions/checkForUserAccount'
import { globalFonts } from '../../shared/globalStyling';


export const AuthenticationView = ({ setUserUID, setLoggedIn }) => {


  const [isSignUp, setIsSignUp] = React.useState(false);

  const signInWithGoogle = async () => {
    const auth = getAuth();
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
  
      if (user) {
        const newUserID = user.uid;
        checkForUserAccount(newUserID, setUserUID, setLoggedIn).then(() => {
          console.log("Check for user account completed.");

        });
      }
  
    } catch (error) {
      console.log("Error during google sign in");
    }
  };

  return (
    <View style={styles.container}>
      <Text style={globalFonts.title2}>{isSignUp ? "Sign Up" : "Login"}</Text>
      {isSignUp ? <SignUpComponent/> : <LoginComponent setUserUID={setUserUID} setLoggedIn={setLoggedIn}  />}

      <Pressable onPress={()=>{setIsSignUp(!isSignUp)}} >
        <Text style={{color: "gray", fontWeight: "bold", fontSize: 16}}>{isSignUp ? "Login" : "Signup"}</Text>
      </Pressable>



      <FlolangDivider thickness={2} width={50} />

      <FlolangButton
        title="Sign in with Google"
        isDisabled={false}
        primaryStyle={true}
        iconName="logo-google" 
        action={signInWithGoogle}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  input: {
    borderBottomWidth: 1,
    width: 200,
    marginBottom: 10,
    padding: 5,
  },
});


