import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { FlolangButton } from '../../shared/UIElements/FlolangButton';
import { viewBools } from '../../shared/viewBoolBus';
import userDetailsManager from '../../managers/UserDetailsManager';
import { FlolangTextButton } from '../../shared/UIElements/FlolangTextButton';
import { auth } from '../../../AppInit'; // Import your auth instance
import { signOut } from 'firebase/auth';  // Import the signOut function
import { globalFonts, globalColors } from '../../shared/globalStyling';

export const FlolangHeader = ( {setLoggedIn, setUserUID, setUsername} ) => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      viewBools.setViewBool("showAuthenticationView", false);
      viewBools.setViewBool("showLanguageSelection", false);
      viewBools.setViewBool("showPartnerSelection", false);
      viewBools.setViewBool("showMessagesWindow", false);
      viewBools.setViewBool("showPaymentPage", false);
      setLoggedIn(false);
      setUserUID("");
      setUsername("");
      secureLog("Logged out successfully.");
    } catch (error) {
      secureLog("Error logging out:", error);
    }
  };

  return (
    <View style={styles.container}>
        <Text style={styles.gradientTitle}>Flolang</Text>

      <View style={styles.rightContainer}>

        <Pressable style={{alignItems: "flex-end"}} onPress={() => {
          viewBools.setViewBool("showLanguageSelection", true)
          viewBools.setViewBool("showAuthenticationView", false);
          viewBools.setViewBool("showPartnerSelection", false);
          viewBools.setViewBool("showMessagesWindow", false);
          viewBools.setViewBool("showPaymentPage", false);
          
          }}>
            <Text style={{margin: 30, fontWeight: "bold", color: globalColors.secondary }} >Change Languages</Text>
          </Pressable>
          <Pressable style={{alignItems: "flex-end"}} onPress={handleLogout}>
            <Text style = {{color: globalColors.primary, fontWeight: "bold", fontSize: "18px" }}>{userDetailsManager.username_str}</Text>
            <Text style = {{color: globalColors.secondary, fontWeight: "bold", fontSize: "15px" }}>Logout</Text>
          </Pressable>

        <FlolangButton title="New" isDisabled={false} primaryStyle={true} iconName={"add-outline"} action={() => {
            viewBools.setViewBool("showMessagesWindow", false);
            viewBools.setViewBool("showPartnerSelection", true);
          }}
        />
        <View style={{flexDirection: "row", justifyContent: "space-between"}}>

        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

  },
  gradientTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: globalColors.primary,
    backgroundImage: "linear-gradient(to bottom right, #3DA3DC, #F622FB)",
    borderRadius: 10, 
    padding: 10,
  }
});