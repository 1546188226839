
import { FlatList, Text, TextInput, View, TouchableOpacity, StyleSheet, Pressable } from 'react-native';
import { BubbleText } from './BubbleComponents/BubbleText';
import { BubbleTranslation } from './BubbleComponents/BubbleTranslation';
import { CircleProgressBar } from './BubbleComponents/CircleProgressBar'
import { FlolangButton } from '../../../shared/UIElements/FlolangButton';
import React, { useState, useEffect } from 'react';
import Icon from 'react-native-vector-icons/Ionicons'; // Importing Ionicons as an example; you can import any set you prefer
import { globalColors, globalFonts } from '../../../shared/globalStyling';

export const MessageBubble = ({ item }) => {

  const [gradePercentage, setGradePercentage] = useState(0);
  const [showTranslation, setShowTranslation] = useState(false);

  const makeGradeMessage = (correctColor_str, wrongColor_str) => {
    const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  
    return (message, translation) => {
      const sourceWords = translation.replace(/["']/g,'').split(' ');
      const cleanSourceWords = sourceWords.map(word => removeAccents(word).toLowerCase());
      const sourceWordsSet = new Set(cleanSourceWords);
  
      const userWords = message.replace(/["']/g,'').split(' ');
      const cleanUserWords = userWords.map(word => removeAccents(word).toLowerCase());
  
      let correctWordCount = 0;
  
      const gradedMessage = userWords.map((word, index) => {
        const isCorrect = sourceWordsSet.has(cleanUserWords[index]);
        if (isCorrect) correctWordCount++;
  
        return (
          <Text
            key={index}
            style={{
              color: isCorrect ? correctColor_str : wrongColor_str, 
            }}
          >
            {word + (index === userWords.length - 1 ? '' : ' ')}
          </Text>
        );
      });
  
      const grade = Math.floor((correctWordCount / userWords.length) * 100);
      setGradePercentage(grade);
  
      return gradedMessage;
    };
  };
  
  return (
    <View style={{ "flexDirection": "row", alignItems: "center" }}>
      <View style={{ "flexDirection": "column", alignSelf: "center" }}>
        {!item.received_bool ? 
        <CircleProgressBar percentage={gradePercentage} /> : 
        <Pressable onPress={() => setShowTranslation(!showTranslation)}>
        <Icon 
          name={showTranslation ? "chevron-up-circle-outline" : "chevron-down-circle-outline"} 
          size={30} 
          color={globalColors.primary}
        />
      </Pressable>
}

      </View>

      <View style={{ "flexDirection": "column", alignItems: item.received_bool ? "flex-start" : "flex-end" }}>
        <View style={{ "flexDirection": "row", alignItems: "center" }}>
          <BubbleText item={item} gradeMessage={makeGradeMessage(globalColors.flolangGreen, "white")} />
        </View>
        {(showTranslation || !item.received_bool) ? <BubbleTranslation item={item} gradeMessage={makeGradeMessage(globalColors.primary, globalColors.flolangGreen)} /> : null}
      </View>
    </View>



  );
};
