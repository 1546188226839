import React from "react";
import { globalColors  } from "../globalStyling";

export const FlolangTextField = ({ placeholderText, varToModify, setVarToModify, onEnterPressed, isPassword }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterPressed();
    }
  }

  return (
    <div style={{ ...styles.container, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <input 
        style={styles.textInput}
        type={isPassword ? "password" : "text"}
        placeholder={placeholderText}
        value={varToModify}
        onChange={(e) => setVarToModify(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

const styles = {
  container: {
    width: '100%', 
    borderRadius: "20px",
    borderWidth: "2px",
    borderColor: "#8122FB",
    overflow: "hidden",
    borderStyle: "solid",
    margin: "10px"
  },
  textInput: {
    width: '100%', 
    minHeight: "52px",
    paddingLeft: "16px",
    paddingRight: "20px",
    color: globalColors.primary,
    backgroundColor: "transparent",
    outline: "none",
    border: "none",
    fontSize: "16px",
    fontWeight: "bold",
  }
};
