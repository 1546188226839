

export const UserDetails = {
  id: "",
  emoji: "",
  username_str: "",
  partnerUsername_str: "",
  userForeignLanguage_str: "",
  userNativeLanguage_str: "",
  interests_arr_str: [],
  deviceToken_str: "",
  isBot: false,
  isPaid: false,
  dailyMessageCounts_arr_obj: [],
  totalMessages_int: 0,
  messagesToday_int: 0,
  daysActive_int: 0
};


