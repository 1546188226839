// FlolangButton.js

import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons'; // Importing Ionicons as an example; you can import any set you prefer

export const FlolangButton = ({ title, isDisabled, primaryStyle, action, iconName }) => {

  // Function to handle button press
  const handlePress = () => {
    if (!isDisabled) {
      action();
    }
  };

  // Computed styles for primary and secondary buttons
  const primaryButtonStyles = [styles.primaryButton, isDisabled && styles.disabled];
  const secondaryButtonStyles = [styles.secondaryButton, isDisabled && styles.disabled];

  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={isDisabled}
      style={primaryStyle ? primaryButtonStyles : secondaryButtonStyles}
    >
      { iconName && <Icon name={iconName} size={20} color={primaryStyle ? 'white' : 'gray'} style={styles.icon} /> }
      <Text style={primaryStyle ? styles.primaryText : styles.secondaryText}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

// React Native StyleSheet for styling the button and the icon
const styles = StyleSheet.create({
  primaryButton: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: "52px",
    justifyContent: 'center',
    backgroundImage: "linear-gradient(to bottom right, #3DA3DC, #F622FB)",
    borderRadius: 25,
    padding: 10,
    margin: 10,

  },
  secondaryButton: {
    flexDirection: 'row',
    minHeight: "52px",

    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(192,192,192, 0.2)',
    borderRadius: 25,
    padding: 10,
    margin: 10
  },
  primaryText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 18,  
    fontWeight: 'bold',  
  },
  secondaryText: {
    color: 'gray',
    textAlign: 'center',
    fontSize: 18,  
    fontWeight: 'bold',  
  },
  disabled: {
    opacity: 0.5
  },
  icon: {
    marginRight: 8  // Adding some margin to separate it from the text
  }
});

